import React, { FC } from 'react';

import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

import Chat, { ChatProps } from './Chat';

export type Offset = {
  left?: number;
  right?: number;
  bottom?: number;
  top?: number;
};

export type Position =
  | 'left-top'
  | 'left-middle'
  | 'left-bottom'
  | 'center-top'
  | 'center-middle'
  | 'center-bottom'
  | 'right-top'
  | 'right-middle'
  | 'right-bottom';

export interface DialogChatProps extends ChatProps {
  position?: Position;
  offset?: Offset;
  width?: number;
  height?: number;
}

const defaultOffset = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
};

const useStyles = makeStyles<Offset | undefined>()((theme, offset) => ({
  left: {
    left: (() => {
      const { left = 0, right = 0 } = Object.assign(defaultOffset, offset);
      return 20 + left - right;
    })(),
  },
  right: {
    right: (() => {
      const { left = 0, right = 0 } = Object.assign(defaultOffset, offset);
      return 20 - left + right;
    })(),
  },
  center: {
    right: (() => {
      const { left = 0, right = 0 } = Object.assign(defaultOffset, offset);
      return `calc(50% + ${right}px + ${left}px)`;
    })(),
  },
  bottom: {
    bottom: (() => {
      const { bottom = 0, top = 0 } = Object.assign(defaultOffset, offset);
      return 20 + bottom - top;
    })(),
  },
  top: {
    top: (() => {
      const { bottom = 0, top = 0 } = Object.assign(defaultOffset, offset);
      return 20 - bottom + top;
    })(),
  },
  middle: {
    bottom: (() => {
      const { bottom = 0, top = 0 } = Object.assign(defaultOffset, offset);
      return `calc(50% + ${top}px + ${bottom}px)`;
    })(),
  },
}));

const DialogChat: FC<DialogChatProps> = ({
  position = 'right-bottom',
  offset,
  width = 360,
  height = 400,
  ...rest
}) => {
  const { classes, cx } = useStyles(offset);
  const [horizontal, vertical] = position.split('-');

  const x = horizontal === 'center' ? 50 : 0;
  const y = vertical === 'middle' ? 50 : 0;

  const transform = `translate(${x}%, ${y}%)`;
  // @ts-ignore
  const xClassName = classes[horizontal];
  // @ts-ignore
  const yClassName = classes[vertical];

  return (
    <Box
      position="fixed"
      zIndex="1300"
      className={cx(xClassName, yClassName)}
      style={{ transform }}
      width={width}
      height={height}
    >
      <Chat {...rest} />
    </Box>
  );
};

export default DialogChat;

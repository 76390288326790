import React from 'react';
import Button, { ButtonProps } from 'components/Button';
import useTranslation from 'hooks/useTranslation';
import { FilterIcon, FilterOffIcon } from 'components/Icons';

export default ({ open, ...rest }: ButtonProps & { open: boolean }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant={open ? 'secondary' : 'primary'}
      startIcon={open ? <FilterOffIcon /> : <FilterIcon />}
      {...rest}
    >
      {t(open ? 'Hide Filters' : 'Show Filters')}
    </Button>
  );
};
